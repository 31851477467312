@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* styles.css */
.datepicker {
  border-radius: 25px;        /* Border radius */
  padding: 8px;              /* Padding inside the input */
  width: 100%;               /* Full width for responsive design */
  box-sizing: border-box;    /* Ensure padding and border are included in width/height */
}

.datepicker:focus {
  outline: none;             /* Remove default outline */
  border-color: #63b3ed;     /* Border color on focus */
  box-shadow: 0 0 0 3px rgba(99, 179, 237, 0.5); /* Add shadow on focus */
}

.error-position{
  z-index: -1 !important;
}

.inputs{
  margin-bottom: 20px;
}